<template>
  <div>
    <section class="blasttv">
      <div class="container">
        <div class="row vh-100">
          <div class="col-lg-12">
            <!-- <h5 class="text-center">Choose SKU</h5> -->
            <div class="text-center">
              <div>
                <img
                  src="../assets/images/blasttv/blasttv-logo.png"
                  alt="blasttv logo"
                  height="80"
                  class="blasttv-logo"
                />
              </div>

              <div class="watch-label">
                Watch <span>Now</span>, Pay <span>Zero</span>
              </div>

              <div>
                <button
                  @click.prevent="goToBlast()"
                  class="btn btn-lg rounded-pill px-5 btn-blasttv"
                >
                  www.blasttv.ph
                </button>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="lower-part">
              <h1 class="pt-4">STREAM 2 SAWA NA!</h1>

              <div>
                <img
                  src="../assets/images/blasttv/s2s-logo.png"
                  alt="s2s logo"
                  height="110"
                />
              </div>

              <div class="text-center">
                <p>
                  Ang mga paborito mong shows at movies ay narito na't
                  mapapanuod mo na sa pinakabagong streaming platform ng bayan,
                  BlastTV!
                </p>

                <div class="lower-label">
                  LIBRE 'to para sa lahat ng Surf 2 Sawa subscribers!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <img src="../assets//images/blasttv/tap-logo.png" alt="tap logo" />
      </div>
    </section>

    <section class="page-section blast-faq">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-7">
            <div class="faq">Frequently Asked Questions</div>

            <h5 class="mt-3">Ano ang BlastTV?</h5>
            <p>
              Ang BLAST TV ay streaming platform na may malawak na pagpipilian
              ng iyong mga paboritong palabas at pelikula. Easy access din ito -
              Pumunta lamang sa <a href="https://blasttv.ph">BLASTTV.ph</a> at
              siguraduhing ikaw ay naka-subscribe sa Surf2Sawa. Sa pinagsanib
              pwersa ng Converge at TapDMV, lahat ng subscriber ng FiberX,
              kasama ang BIDA at Surf2Sawa, ay may libreng access sa BLAST TV.
            </p>

            <h5 class="mt-3">Ano ang makikita sa BlastTV?</h5>
            <p>
              Ang BlastTV ay nagbibigay ng malawak na pagpipilian na mga
              programa, channels, at interactive experiences na exclusive para
              sa mga subscribers ng FiberX kasama na ang BIDA at Surf2Sawa.
            </p>
            <p>
              Ang Blast TV ay para sa lahat. Binibigyang halaga ng BlastTV ang
              pangangailangan ng lahat. Mula sa mga pelikula at mga sports event
              hanggang sa balita, game show, horror, reality shows, factual
              entertainment, WWE at UFC bouts. Sinisiguro namin na may malawak
              na pagpipilian ng mga palabas na akma sa lahat ng panlasa at
              interest.
            </p>
            <p>
              Sa BLAST TV, maaari kang pumili mula sa iba't-ibang subscription
              options upang bigyan ang mga subscribers ng kalayaan na pumili ng
              kanilang gustong mapanood mula sa free video-on-demand content.
            </p>
            <p>
              Gamit ang advanced technology at maaliwalas na interface,
              makakaranas ng immersive experince sa panonood ang BLASTTV. Bukod
              sa pagtataguyod ng mataas na pamantayan sa kalidad, hangad naming
              matugunan ang kasiyahan ng aming mga manonood sa larangan ng
              kreatibidad at customer service. Sa paglulunsad nito, magtatampok
              ito ng 23+ channels at 300 titles upang masigurong mayroong
              malawak na hanay ng mga pagpipilian sa entertainment.
            </p>
            <p>
              Mag subscribe na sa Converge at BlastTV at mag-enjoy sa mundo ng
              entertainment ng Want2Sawa!
            </p>

            <h5 class="mt-3">Paano ma-access ang BLASTTV?</h5>
            <!-- <p> -->
            <ol>
              <li>Siguraduhing naka-connect sa Converge Internet.</li>
              <li>
                Pumunta sa <a href="https://blasttv.ph">www.BLASTTV.ph</a>
              </li>
              <li>Mag-log in gamit ang iyong S2S user account.</li>
              <li>Mamili ng iyong paboritong programa na papanoorin.</li>
            </ol>
            <!-- </p> -->

            <h5 class="mt-3">
              Maaari ko pa rin bang ma-access ang BLASTTV kahit hindi ako
              gumagamit ng Converge Internet?
            </h5>
            <p>
              Hindi maaari. Ang iyong access sa BLASTTV ay limitado lamang sa
              mga subscriber ng Converge FiberX/S2S/BIDA Internet.
            </p>

            <h5 class="mt-3">Gaano katagal ang libreng access sa site?</h5>
            <p>
              Hangga't ikaw ay isang active subscriber ng Converge
              FiberX/S2S/BIDA, mapapanood mo ang mga palabas ng BLASTTV nang
              walang sawa.
            </p>

            <h5 class="mt-3">
              Paano mag-browse at manood ng mga paboritong palabas sa telebisyon
              na walang ads?
            </h5>
            <p>
              Sa ngayon, hindi pa ito posible. Nag-aapply ang mga ad sa lahat ng
              mga gumagamit.
            </p>

            <h5 class="mt-3">
              Maaari ko bang ma-access ang aking account sa maraming devices
              nang sabay-sabay?
            </h5>
            <p>Oo.</p>

            <h5 class="mt-3">
              Maaari bang panoorin ang mga shows sa BLASTTV nang offline?
            </h5>
            <p>
              Sa ngayon, hindi pa posible. Ang lahat ng shows sa blastTV ay
              maari lamang i-stream.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import SkuItem from "../components/SkuItem.vue";
export default {
  name: "Gifting",
  components: {
    // SkuItem,
  },
  data() {
    return {
      skus: [],
    };
  },
  methods: {
    goToBlast() {
      window.location.href = "https://blasttv.ph";
    },
  },

  created() {},
};
</script>
